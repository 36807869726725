import React, { useEffect, useState } from "react";
import {
    Card,
    Stack,
    Typography,
    TextField,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import UserListHead from "../user/UserListHead";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const token = localStorage.getItem("token");
const authToken = token;
const config = {
    headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
    },
};
export default function UserTrackGoal({ subscriptionInfo }) {
    const navigate = useNavigate();
    const [filteredSubscriptionInfo, setFilteredSubscriptionInfo] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const [userTrackInfo, setUserTrackInfo] = useState([]);
    //   const [filteredSubscriptionInfo, setFilteredSubscriptionInfo] = useState([]);
    //   const [searchInput, setSearchInput] = useState("");
    //   const [page, setPage] = useState(0);
    //   const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        // Fetch data from API
        const fetchUserTrackInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/user-track`, config);
                setUserTrackInfo(response.data.data); // Assuming the API response is an array
                setFilteredSubscriptionInfo(response.data.data); // Set initial filtered data
            } catch (error) {
                console.error("Error fetching user track data:", error);
            }
        };

        fetchUserTrackInfo();
    }, []);
    useEffect(() => {
        setFilteredSubscriptionInfo(subscriptionInfo);
    }, [subscriptionInfo]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target?.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - filteredSubscriptionInfo?.length)
            : 0;
    const TABLE_HEAD = [
        { id: "track_id", label: "Track ID", alignRight: false },
        { id: "user_name", label: "User Name", alignRight: false },
        { id: "user_id", label: "User ID", alignRight: false },
        { id: "subscription_title", label: "Subscription Title", alignRight: false },
        { id: "test_series_name", label: "Test Series Name", alignRight: false },
        { id: "course_name", label: "Course Name", alignRight: false },
        { id: "created_at", label: "Created At", alignRight: false },
    ];

    const handleSearchInputChange = (event) => {
        const searchValue = event.target?.value;
        setSearchInput(searchValue);
        if (searchValue === "") {
            setFilteredSubscriptionInfo(subscriptionInfo);
        } else {
            const filteredData = subscriptionInfo?.filter(
                (item) =>
                    item?.user_name &&
                    item?.user_name?.toLowerCase()?.includes(searchValue.toLowerCase()) || 
                    item?.subscription_title?.toLowerCase()?.includes(searchValue.toLowerCase())|| 
                    item?.test_series_name?.toLowerCase()?.includes(searchValue.toLowerCase())||
                    item?.course_name?.toLowerCase()?.includes(searchValue.toLowerCase())
            );
            setPage(0);
            setFilteredSubscriptionInfo(filteredData);
        }
    };

    const handleRowClick = () => {
        toast.info("We are working on this feature. It will be available soon.");
    };


    return (
        <Card pr={3} pl={3} className="px-4">
            <Stack
                pr={3}
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="center"
            >
                <TextField
                    placeholder="Search Title"
                    variant="outlined"
                    margin="normal"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    sx={{
                        width: "300px",
                        height: "15px",
                        marginBottom: "70px",
                        "& label": {
                            color: "black",
                        },
                        "& fieldset": {
                            borderColor: "black",
                        },
                    }}
                />
            </Stack>

            <Scrollbar>
                <TableContainer
                    style={{ textWrap: "nowrap" }}
                    className="text-nowrap"
                    sx={{ minWidth: 800 }}
                >
                    <Table>
                        <UserListHead
                            order="asc"
                            orderBy="title"
                            headLabel={TABLE_HEAD}
                            rowCount={filteredSubscriptionInfo?.length}
                            noWrap
                        />

                        <TableBody>
                            {userTrackInfo
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row, index) => {
                                    const {
                                        track_id, // Maps to Track ID
                                        user_name, // Maps to User Name
                                        user_id, // Maps to User ID
                                        subscription_title, // Maps to Subscription Title
                                        test_series_name, // Maps to Test Series Name
                                        course_name, // Maps to Course Name
                                        created_at, // Maps to Created At
                                    } = row;

                                    return (
                                        <TableRow
                                            hover
                                            key={track_id || index}
                                            tabIndex={-1}
                                            role="checkbox"
                                            style={{ cursor: "pointer" }}
                                         
                                        >
                                          

                                            {/* Track ID */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {track_id || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* User Name */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {user_name || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* User ID */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {user_id || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* Subscription Title */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {subscription_title || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* Test Series Name */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {test_series_name || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* Course Name */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {course_name || "N/A"}
                                                </Typography>
                                            </TableCell>

                                            {/* Created At */}
                                            <TableCell>
                                                <Typography variant="" noWrap>
                                                    {created_at?.substring(0, 10) || "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                            {/* Fill Empty Rows for Pagination */}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={TABLE_HEAD.length} />
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredSubscriptionInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>

    );
}
