import {
  Navigate,
  useRoutes,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
// import Blog from './pages/Blog';
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
// import Products from './pages/Products';
import DashboardApp from "./pages/DashboardApp";
import LoginTeacher from "./pages/LoginTeacher";
import Student from "./pages/User";
import Batch from "./sections/@dashboard/batch/Batch";
import Test from "./sections/@dashboard/testSeries/test";
import AddTestSeries from "./sections/@dashboard/testSeries/AddTestSeries";
import AddBatch from "./sections/@dashboard/batch/AddBatch";
import BatchDetails from "./sections/@dashboard/batch/BatchDetails";
import Staff from "./sections/@dashboard/manageStaff/Staff";
import LectureForm from "./sections/@dashboard/batch/LectureForm";
import LiveLecture from "./sections/@dashboard/batch/LiveLecture";
import Banner from "./sections/@dashboard/generalSettiing/Banner";
import ContactUs from "./sections/@dashboard/generalSettiing/ContactUs";
import StudentBatches from "./sections/@dashboard/user/StudentBatches";
import SubAdmin from "./sections/@dashboard/manageStaff/SubAdmin";
import Moderators from "./sections/@dashboard/manageStaff/Moderators";
import Assignment from "./sections/@dashboard/assignmennt/Assignment";
import Testimonial from "./sections/@dashboard/testimonial/Testimonial";
import AIR from "./sections/@dashboard/resources/AIR";
import CustomNotification from "./sections/@dashboard/resources/CustomNotification";
import AddCustomNotification from "./sections/@dashboard/resources/AddCustomNotification";
import NewsClip from "./sections/@dashboard/resources/NewsClip";
import TeacherGenerated from "./sections/@dashboard/resources/TeacherGenerated";
import Pathyakram from "./sections/@dashboard/resources/Pathyakram";
import Notes from "./sections/@dashboard/resources/Notes";
import LiveStream from "./sections/@dashboard/livestream/LiveStream";
import Category from "./sections/@dashboard/generalSettiing/Category";
import Youtube from "./sections/@dashboard/generalSettiing/Youtube";
import Audio from "./sections/@dashboard/resources/Audio";
import HelpAndSupport from "./sections/@dashboard/generalSettiing/HelpAndSupport";
import Telegram from "./sections/@dashboard/generalSettiing/Telegram";
import Subject from "./sections/@dashboard/generalSettiing/Subject";
import AddResources from "./sections/@dashboard/resources/AddResources";
import Resources from "./sections/@dashboard/resources/Resources";
import LectureResources from "./sections/@dashboard/batch/LectureResources";
import Profile from "./sections/@dashboard/profile/Profile";
import Messaging from "./sections/@dashboard/messaging/Messaging";
import LogoutUsers from "./sections/@dashboard/generalSettiing/LogoutUsers";
import Recorded from "./sections/@dashboard/resources/Recorded";
import AddRecordedVideo from "./sections/@dashboard/resources/AddRecordedVideo";
// import RecordedTable from './sections/@dashboard/resources/RecordedTable';
import RecordedLectures from "./sections/@dashboard/batch/RecordedLectures";
import StudentList from "./sections/@dashboard/user/StudentList";
import StudentProfile from "./sections/@dashboard/user/StudentProfile";
import TeacherProfile from "./sections/@dashboard/manageStaff/TeacherProfile";
import AssignTeacherToBatch from "./sections/@dashboard/batch/AssignTeacherToBatch";
import UpdateBatchDetails from "./sections/@dashboard/batch/UpdateBatchDetails";
import UpdateLectureDetails from "./sections/@dashboard/batch/UpdateLectureDetails";
import TestSeriesDetails from "./sections/@dashboard/testSeries/TestSeriesDetails";
import AddTest from "./sections/@dashboard/testSeries/AddTest";
import UpdateTestSeries from "./sections/@dashboard/testSeries/UpdateTestSeries";
import AttemptedTest from "./sections/@dashboard/testSeries/AttemptedTest";
import StudentTestSeries from "./sections/@dashboard/testSeries/StudentTestSeries";
import StudentTestSeriesList from "./sections/@dashboard/testSeries/StudentTestSeriesList";
import UpdateTest from "./sections/@dashboard/testSeries/UpdateTest";
// import APP from './backgroundUpload/APP';
// import FlutterLiveStream from './flutterLiveStream/FlutterLiveStream';
// import UpdatePermission from "./sections/@dashboard/manageStaff/Components/UpdatePermission";
import UpdateStaff from "./sections/@dashboard/manageStaff/UpdateStaff";
// import QuizQuestions from "./sections/@dashboard/quiz/QuizQuestions";
import Quiz from "./sections/@dashboard/quiz/Quiz";
import AddQuizQuestion from "./sections/@dashboard/quiz/AddQuizQuestion";
import AddQuiz from "./sections/@dashboard/quiz/AddQuiz";
// import EditQuestion from "./sections/@dashboard/quiz/Quiz";
import EditQuestion from "./sections/@dashboard/quiz/EditQuestion";
import UpdateQuiz from "./sections/@dashboard/quiz/UpdateQuiz";
import IssuesTable from "./sections/@dashboard/quiz/IssuesTable";
import DoubtsTable from "./sections/@dashboard/quiz/DoubtsTable";
import QuizTable from "./sections/@dashboard/quiz/QuizTable";
import UpdateQuizQuestions from "./sections/@dashboard/quiz/UpdateQuizQuestions";
import QuizStudents from "./sections/@dashboard/quiz/QuizStudents";
import QuizSResults from "./sections/@dashboard/quiz/QuizResults";
import StudentResults from "./sections/@dashboard/quiz/StudentResults";
import AllCoupon from "./sections/@dashboard/coupon/AllCoupon";
import AddCoupon from "./sections/@dashboard/coupon/AddCoupon";
import Oath2callback from "./sections/@dashboard/Oath2callback";
import LeaderBoard from "./sections/@dashboard/quiz/LeaderBoard";
import SubCategory from "./sections/@dashboard/generalSettiing/SubCategory";
import PreviousYearQuestionPapers from "./sections/@dashboard/resources/PreviousYearQuestionPapers";
import UserReport from "./pages/UserReport";
import Students from "./pages/Students";
import YoutubeLive from "./sections/@dashboard/batch/YoutubeLive";
import CenterLectureForm from "./sections/@dashboard/batch/CenterLectureForm";
import AddCenterLectureResources from "./sections/@dashboard/batch/AddCenterLectureResources";
import AddQuizQuestionsNew from "./sections/@dashboard/quiz/AddQuizQuestionsNew";
import { MathJaxContext } from "better-react-mathjax";
import Subscriptions from "./sections/@dashboard/subscriptions/Subscriptions";
import MockTest from "./sections/@dashboard/mockTest/MockTest";
import Manual from "./sections/@dashboard/mockTest/Manual";
import Template from "./sections/@dashboard/mockTest/Template";
import MockTable from "./sections/@dashboard/mockTest/components/MockTable";
import AddMockQuestionsNew from "./sections/@dashboard/mockTest/components/AddMockQuestionsNew";
import UpdateManualTest from "./sections/@dashboard/mockTest/components/UpdateManualTest";
import UpdateTemplateTest from "./sections/@dashboard/mockTest/components/UpdateTemplateTest";
import UpdateSubscription from "./sections/@dashboard/subscriptions/UpdateSubscription";
import UpdateBanner from "./sections/@dashboard/generalSettiing/UpdateBanner";
import UpdateCoupon from "./sections/@dashboard/coupon/UpdateCoupon";
import SubscriptionList from "./sections/@dashboard/testSeries/SubscriptionList";
import Feedback from "./sections/@dashboard/generalSettiing/Feedback";
import Notification from "./sections/@dashboard/notifications/Notification";
import ExamGroups from "./sections/@dashboard/exams/ExamGroups";
import SocialMedia from "./sections/@dashboard/social/Social";
import Question from "./sections/@dashboard/generalSettiing/Question";
import UserTrackGoal from "./sections/@dashboard/user-track/Index";

// ----------------------------------------------------------------------

export default function Router() {
  const LoggedIn = localStorage.getItem("isLoggedIn");
  //Checking for access
  const detailObject = localStorage.getItem("detailObject");
  let d = JSON.parse(detailObject);

  const role = localStorage.getItem("role");
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        // { path: "message", element: <Messaging /> },
        // { path: "logoutUsers", element: <LogoutUsers /> },
        // { path: "students", element: <Student /> },
        { path: "teacher/teacherProfile/:data", element: <TeacherProfile /> },
        { path: "students/studentsProfile/:data", element: <StudentProfile /> },
        { path: "profile", element: <Profile /> },
        // { path: "studentBatches", element: <StudentBatches /> },
        {
          path: "studentBatches/studentList/:active/:batchId",
          element: <StudentList />,
        },
        // { path: 'products', element: <Products /> },
        // { path: 'youtubeLive/:lectId/:lectTitle', element: <YoutubeLive /> },
        {
          path: "youtubeLive/:lectId/:lectTitle/:lectDesc/:ytUrl",
          element: <YoutubeLive />,
        },
        // { path: "batches", element: <Batch /> },
        // { path: "userReport", element: <UserReport /> },
        // { path: "purchaseReport", element: <Students /> },
        // { path: "batches", element: <Batch /> },
        {
          path: "batches/addTeacherToBatch/:batchId",
          element: <AssignTeacherToBatch />,
        },
        {
          path: "batches/updateBatchDetails/:batchId",
          element: <UpdateBatchDetails />,
        },
        { path: "helpAndSupport", element: <HelpAndSupport /> },
        // { path: "addBatch", element: <AddBatch /> },
        { path: "batchDetails/:Id", element: <BatchDetails /> },
        {
          path: "batchDetails/updateLectureDetails/:batchId/:lectId",
          element: <UpdateLectureDetails />,
        },
        {
          path: "batchDetails/addLecture/:Id/:batchName",
          element: <LectureForm />,
        },
        {
          path: "batchDetails/liveLecture/:Id/:batchName",
          element: <LiveLecture />,
        },
        // { path: "test", element: <Test /> },
        {
          path: "test/testSeriesDetails/updateTestDetails/:testSeriesId/:testId",
          element: <UpdateTest />,
        },
        // { path: "studentTestSeries", element: <StudentTestSeries /> },
        {
          path: "studentTestSeries/studentList/:active/:testSeriesId",
          element: <StudentTestSeriesList />,
        },
        {
          path: "subscription/students/:active/:id",
          element: <SubscriptionList />,
        },
        {
          path: "test/updateTestSeries/:testSeriesId",
          element: <UpdateTestSeries />,
        },
        {
          path: "test/testSeriesDetails/:testSeriesId",
          element: <TestSeriesDetails />,
        },
        {
          path: "test/editquestion/:testSeriesId/:questionId/:data",
          element: <EditQuestion />,
        },
        {
          path: "test/editquestion/",
          element: <EditQuestion />,
        },
        {
          path: "test/testSeriesDetails/attemptedTest/:testSeriesId",
          element: <AttemptedTest />,
        },
        {
          path: "test/testSeriesDetails/addTest/:testSeriesId",
          element: <AddTest />,
        },
        {
          path: "mockTest",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("All Mock Test") ? (
              <Navigate to="/404" replace />
            ) : (
              <MockTest />
            ),
        },
        {
          path: "manual",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Add Manual Test") ? (
              <Navigate to="/404" replace />
            ) : (
              <Manual />
            ),
        },
        {
          path: "template",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Add Template Test") ? (
              <Navigate to="/404" replace />
            ) : (
              <Template />
            ),
        },
        {
          path: "updateManualTest/:manualId",
          element: <UpdateManualTest />,
        },
        {
          path: "updateTemplateTest/:templateId",
          element: <UpdateTemplateTest />,
        },
        { path: "recordedLectures", element: <RecordedLectures /> },
        // { path: "addTest", element: <AddTestSeries /> },
        // { path: "staff", element: <Staff /> },
        { path: "staff/updateStaff/:teacherId", element: <UpdateStaff /> },
        // {
        //   path: "staff/updatePermission/:teacherId",
        //   element: <UpdatePermission />,
        // },
        { path: "moderators", element: <Moderators /> },
        { path: "subAdmin", element: <SubAdmin /> },
        // { path: "banner", element: <Banner /> },
        //{ path: "updatebaner/:id", element: <UpdateBanner /> },
        {
          path: "audio",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('Audios') || d?.accessToContent?.includes('Add Audio')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Audio />
            ),
        },
        {
          path: "subscription",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('All Subscriptions') || d?.accessToContent?.includes('Add Subscription')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Subscriptions />
            ),
        },
        // { path: "subscription", element: <Subscriptions /> },
        { path: "updateSubscription/:subsId", element: <UpdateSubscription /> },
        {
          path: "youtube",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('Demo Videos') || d?.accessToContent?.includes('Add Demo Videos')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Youtube />
            ),
        },
        // { path: "telegram", element: <Telegram /> },
        { path: "contactUs", element: <ContactUs /> },
        { path: "category", element: <Category /> },
        // { path: "subCategory", element: <SubCategory /> },
        // { path: "subject", element: <Subject /> },
        { path: "addResources", element: <AddResources /> },
        { path: "customNotification", element: <CustomNotification /> },
        { path: "addCustomNotification", element: <AddCustomNotification /> },
        { path: "resources", element: <Resources /> },
        {
          path: "lectureResources/:lectId/:batchId",
          element: <LectureResources />,
        },
        // { path: "assignment", element: <Assignment /> },
        // { path: "testimonial", element: <Testimonial /> },
        {
          path: "Pathyakram",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('Syllabus') || d?.accessToContent?.includes('Add Syllabus')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Pathyakram />
            ),
        },
        // { path: "recorded", element: <Recorded /> },
        { path: "addRecorded/:lectId/:batchId", element: <AddRecordedVideo /> },
        // { path: "air", element: <AIR /> },
        { path: "newsClip", element: <NewsClip /> },
        {
          path: "notes",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('Notes') || d?.accessToContent?.includes('Add Notes')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Notes />
            ),
        },
        // { path: "previousYearQuestionPapers", element: <PreviousYearQuestionPapers /> },
        { path: "quiz", element: <Quiz /> },
        { path: "quiz/doubts/:quizName/:quizId", element: <DoubtsTable /> },
        { path: "quiz/issues/:quizName/:quizId", element: <IssuesTable /> },
        {
          path: "quiz/quizStudents/:quizName/:quizId",
          element: <QuizStudents />,
        },

        //User
        {
          path: "students",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('All Users') || d?.accessToContent?.includes('Purchase Report')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Student />
            ),
        },
        {
          path: "studentBatches",
          element:
            role !== "Admin" &&
              !(d?.accessToContent?.includes("Course Purchases") ||
                d?.accessToContent?.includes("Test Series Purchases") ||
                d?.accessToContent?.includes("Subscription Purchases")) ? (
              <Navigate to="/404" replace />
            ) : (
              <StudentBatches />
            ),
        },
        {
          path: "user-track-goal",
          element:
              <UserTrackGoal />
            
        },
        // {
        //   path: "studentTestSeries", element: role !== 'Admin' && !d?.accessToContent?.includes('Test Series Purchases') ? <Navigate to="/404" replace /> : <StudentTestSeries />

        // }

        {
          path: "purchaseReport",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Purchase Report") ? (
              <Navigate to="/404" replace />
            ) : (
              <Students />
            ),
        },
        {
          path: "userReport",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("User Report") ? (
              <Navigate to="/404" replace />
            ) : (
              <UserReport />
            ),
        },
        //Batch
        {
          path: "batches",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("All Batches") ? (
              <Navigate to="/404" replace />
            ) : (
              <Batch />
            ),
        },
        {
          path: "addBatch",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Add Batch") ? (
              <Navigate to="/404" replace />
            ) : (
              <AddBatch />
            ),
        },
        {
          path: "addCenterLecture",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Add Center Lecture") ? (
              <Navigate to="/404" replace />
            ) : (
              <CenterLectureForm />
            ),
        },
        {
          path: "addCenterResource",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Add Center Resource") ? (
              <Navigate to="/404" replace />
            ) : (
              <AddCenterLectureResources />
            ),
        },

        //Test Series
        {
          path: "test",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("All Test Series") ? (
              <Navigate to="/404" replace />
            ) : (
              <Test />
            ),
        },
        {
          path: "exam-groups",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("All Test Series") ? (
              <Navigate to="/404" replace />
            ) : (
             <ExamGroups/>
            ),
        },
        {
          path: "addTest",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Add Test Series") ? (
              <Navigate to="/404" replace />
            ) : (
              <AddTestSeries />
            ),
        },

        //Quiz

        {
          path: "quiz",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("All Quiz") ? (
              <Navigate to="/404" replace />
            ) : (
              <Quiz />
            ),
        },
        {
          path: "addQuiz",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Add Quiz") ? (
              <Navigate to="/404" replace />
            ) : (
              <AddQuiz />
            ),
        },

        //Coupon
        {
          path: "allCoupon",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes("All Coupon")||d?.accessToContent?.includes("Add Coupon")) ? (
              <Navigate to="/404" replace />
            ) : (
              <AllCoupon />
            ),
        },
        {
          path: "addCoupon",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Add Coupon") ? (
              <Navigate to="/404" replace />
            ) : (
              <AddCoupon />
            ),
        },

        { path: "updatecoupon/:id", element: <UpdateCoupon /> },

        //Resources
        {
          path: "recorded",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Recorded Video") ? (
              <Navigate to="/404" replace />
            ) : (
              <Recorded />
            ),
        },
        // {
        //   path: "Pathyakram",
        //   element:
        //     role !== "Admin" && !d?.accessToContent?.includes("Pathyakram") ? (
        //       <Navigate to="/404" replace />
        //     ) : (
        //       <Pathyakram />
        //     ),
        // },
        // {
        //   path: "notes",
        //   element:
        //     role !== "Admin" && !d?.accessToContent?.includes("Notes") ? (
        //       <Navigate to="/404" replace />
        //     ) : (
        //       <Notes />
        //     ),
        // },

        {
          path: "previousYearQuestionPapers",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Previous Year Question Papers") ? (
              <Navigate to="/404" replace />
            ) : (
              <PreviousYearQuestionPapers />
            ),
        },
        {
          path: "air",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("AIR") ? (
              <Navigate to="/404" replace />
            ) : (
              <AIR />
            ),
        },
        {
          path: "newsClip",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("News Clip") ? (
              <Navigate to="/404" replace />
            ) : (
              <NewsClip />
            ),
        },
        {
          path: "teacherGenerated",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes(
                "Teacher Generated Content Of Batch"
              ) ? (
              <Navigate to="/404" replace />
            ) : (
              <TeacherGenerated />
            ),
        },
        {
          path: "customNotification",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Alerts") ? (
              <Navigate to="/404" replace />
            ) : (
              <CustomNotification />
            ),
        },
        // {
        //   path: "notification",
        //   element: <Notification />,
        // },
        {
          path: "notification",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('All Notifications') || d?.accessToContent?.includes('Add Notification')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Notification />
            ),
        },
        {
          path: "question/:id",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes('All Notifications') || d?.accessToContent?.includes('Add Notification')) ? (
              <Navigate to="/404" replace />
            ) : (
              <Question />
            ),
        },

        //!Assignment temperory remove
        // {
        //   path: "assignment", element: role !== 'Admin' && !d?.accessToContent?.includes('All Assignment') ? <Navigate to="/404" replace /> : <Assignment />

        // },

        //Manage Staff
        {
          path: "staff",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("All Staff") ? (
              <Navigate to="/404" replace />
            ) : (
              <Staff />
            ),
        },
        //Testimonial
        // !temperory comment
        // {
        //   path: "testimonial", element: role !== 'Admin' && !d?.accessToContent?.includes('All Testimonial') ? <Navigate to="/404" replace /> : <Testimonial />

        // },

        //General Setting
        {
          path: "banner",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes("Banner")|| d?.accessToContent?.includes("Add Banner")) ? (
              <Navigate to="/404" replace />
            ) : (
              <Banner />
            ),
        },
        {
          path: "logoutUsers",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Logout Users") ? (
              <Navigate to="/404" replace />
            ) : (
              <LogoutUsers />
            ),
        },

        { path: "updatebaner/:id", element: <UpdateBanner /> },

        {
          path: "subject",
          element:
            role !== "Admin" && !(d?.accessToContent?.includes("Subject")||d?.accessToContent?.includes("Add Subject")) ? (
              <Navigate to="/404" replace />
            ) : (
              <Subject />
            ),
        },
        {
          path: "feedback",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Subject") ? (
              <Navigate to="/404" replace />
            ) : (
              <Feedback />
            ),
        },
        {
          path: "social-media",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Subject") ? (
              <Navigate to="/404" replace />
            ) : (
              <SocialMedia />
            ),
        },

        // {
        //   path: "youtube",
        //   element:
        //     role !== "Admin" && !d?.accessToContent?.includes("Youtube") ? (
        //       <Navigate to="/404" replace />
        //     ) : (
        //       <Youtube />
        //     ),
        // },
        //!temperory remove
        // {
        //   path: "telegram", element: role !== 'Admin' && !d?.accessToContent?.includes('Telegram') ? <Navigate to="/404" replace /> : <Telegram />

        // },
        {
          path: "category",
          element:
            role !== "Admin" && !d?.accessToContent?.includes("Category") ? (
              <Navigate to="/404" replace />
            ) : (
              <Category />
            ),
        },

        //!temperory remove
        // {
        //   path: "subCategory", element: role !== 'Admin' && !d?.accessToContent?.includes('Sub Category') ? <Navigate to="/404" replace /> : <SubCategory />

        // },
        //!temperory remove
        // {
        //   path: "contactUs", element: role !== 'Admin' && !d?.accessToContent?.includes('Contact Us') ? <Navigate to="/404" replace /> : <ContactUs />

        // },
        {
          path: "message",
          element:
            role !== "Admin" &&
              !d?.accessToContent?.includes("Help And Support") ? (
              <Navigate to="/404" replace />
            ) : (
              <Messaging />
            ),
        },

        {
          path: "quiz/leaderboard/:quizId/:quizName",
          element: <LeaderBoard />,
        },
        {
          path: "quiz/quizResults/:quizName/:quizId",
          element: <QuizSResults />,
        },
        {
          path: "quiz/editquestion/:quizId/:selectedIdOfQuestion/:quizTitle",
          element: <EditQuestion />,
        },
        { path: "addQuiz", element: <AddQuiz /> },
        {
          path: "updateQuizQuestions/:questionId",
          element: <UpdateQuizQuestions />,
        },
        { path: "updateQuiz/:quizId", element: <UpdateQuiz /> },
        {
          path: "addQuizQuestion/:quizTitle/:quizId",
          element: <AddQuizQuestion />,
          element: <AddQuizQuestionsNew />,
        },
        // { path: "quiz/:quizTitle/:quizId", element: <QuizQuestions /> },
        {
          path: "quiz/:quizTitle/:quizId/:studentId",
          element: <StudentResults />,
        },
        { path: "quiz/:quizTitle/:quizId", element: <QuizTable /> },
        // { path: "allCoupon", element: <AllCoupon /> },
        // { path: "addCoupon", element: <AddCoupon /> },
        // { path: "teacherGenerated", element: <TeacherGenerated /> },
        {
          path: "livestream/:channul/:lectId/:batchId/:desc",
          element: <LiveStream />,
        },
        {
          path: "mockTest/:quizTitle/:quizId/:countData",
          element: <MockTable />,
        },
        {
          path: "addMockQuestion/:quizTitle/:quizId",
          element: <AddMockQuestionsNew />,
        },
      ],
    },
    { path: "oauth/callback/", element: <Oath2callback /> },

    //{ path: "oauth2callback/", element: <Oath2callback /> },
    {
      path: "login",
      element: <Login />,
    },
    // {
    //   path: "flutter",
    //   element: <FlutterLiveStream />,
    // },
    // {
    //   path: 'file',
    //   element: <APP />,
    // },
    {
      path: "loginTeacher",
      element: <LoginTeacher />,
    },

    {
      path: "/",
      element: LoggedIn ? <Navigate to="/dashboard/app" replace /> : <Login />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
